import * as React from "react"
import styled from "styled-components"
import Masonry from "react-masonry-component"
import NewsItem from "../components/Home/News-Item"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { PageButton } from "../components/UI/Buttons"
import ShareBanner from "../components/UI/share-banner"
import { GatsbyImage } from "gatsby-plugin-image"
import loadable from "@loadable/component"
import ActionButton from "../components/UI/call-to-action"

const CaseStudy = loadable(() => import("../components/Home/homepage-carousel"))
const ContactForm = loadable(() => import("../components/UI/contact-form"))

const WhoBox = styled.div`
  .layout-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 10%;
  }

  .image-section {
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    clip-path: polygon(0 0, 100% 0, 50% 100%);
    overflow: hidden;

    p {
      margin: -5em 0 auto auto;
      text-align: right;
      width: 37%;
    }
  }

  .copy-section {
    display: flex;
    margin: 5% 10%;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
  }

  h1 {
    text-align: center;
    padding: 2em;
  }

  @media (max-width: 1024px) {
    .layout-box {
      display: block;
    }
    .copy-section {
      margin: 5% 0;
    }
  }
`

// All the bits in Home that relate to the About Page
const Who = ({ title, image, tagline, html }) => {
  return (
    <WhoBox>
      <h1>{title}</h1>
      <div className="layout-box" style={{ padding: "0" }}>
        <div className="image-section">
          <GatsbyImage
            className="image-clipped"
            image={image.childImageSharp.gatsbyImageData}
            alt="seedling"
          />
          <p>{tagline}</p>
        </div>
        <div className="copy-section">
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <PageButton styleName="black" linkRef="/about">
            Find Out More
          </PageButton>
        </div>
      </div>
    </WhoBox>
  )
}

class IndexPage extends React.Component {
  render() {
    const { data } = this.props
    const insights = data.allMarkdownRemark.edges.filter(
      edge => edge.node.frontmatter.cms === "insight"
    )
    const caseStudies = data.allMarkdownRemark.edges.filter(
      edge =>
        edge.node.frontmatter.cms === "case" && edge.node.frontmatter.carousel
    )
    const page = data.allFile.edges[0].node.childMarkdownRemark

    //Sort the array so that it's in date order.
    function compare(a, b) {
      var dateA = new Date(a.node.frontmatter.date).getTime()
      var dateB = new Date(b.node.frontmatter.date).getTime()

      if (dateA < dateB) return 1
      if (dateA > dateB) return -1
      return 0
    }

    //Sort the array so that if something is 'pinned' it goes straight to the top.
    function pin(a) {
      if (a.node.frontmatter.pin) return -1
      return 0
    }

    insights.sort(compare).sort(pin)
    //The length of the News Roll is defined by 'length' in CMS.
    const size = page.frontmatter.length
    const MasonryElement = insights.slice(0, size).map(({ node }) => {
      const insights = node.frontmatter
      const body = node.html
      const excerpt = node.excerpt

      return (
        <NewsItem
          key={node.fields.slug}
          slug={node.fields.slug}
          items={insights}
          excerpt={excerpt}
          body={body}
        />
      )
    })

    return (
      <Layout>
        <Seo title="Home" />
        <Masonry
          className="grid"
          enableResizableChildren={true}
          options={{
            gutter: 15,
            horizontalOrder: true,
            fitWidth: true,
            itemSelector: ".grid-item",
          }}
          style={{ paddingTop: "2em" }}
        >
          {MasonryElement}
        </Masonry>
        <ActionButton button="Insights" link="/insights">
          {page.frontmatter.call_home}
        </ActionButton>
        <CaseStudy items={caseStudies} />
        <Who
          image={page.frontmatter.who_image}
          title={page.frontmatter.who_title}
          tagline={page.frontmatter.tagline}
          html={page.html}
        />
        <ContactForm />
        <ShareBanner />
      </Layout>
    )
  }
}

export const query = graphql`
  {
    allFile(filter: { name: { eq: "home" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              call_home
              length
              who_title
              tagline
              contact_title
              who_image {
                absolutePath
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [PNG, WEBP, AVIF]
                    layout: FULL_WIDTH
                  )
                }
              }
            }
            html
          }
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          fields {
            slug
          }
          html
          excerpt
          frontmatter {
            carousel
            cms
            color
            date
            quote {
              text
              author
            }
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [PNG, WEBP, AVIF]
                  layout: FULL_WIDTH
                )
              }
            }
            logo {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [PNG, WEBP, AVIF]
                  layout: FULL_WIDTH
                )
              }
            }
            pin
            hshift
            title
            type
          }
        }
      }
    }
  }
`

export default IndexPage
