import React, { useState, useLayoutEffect, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from "prop-types"
import { PageButton } from '../UI/Buttons'
import { GatsbyImage } from "gatsby-plugin-image"


const Box = styled.div `
    position: relative;
    width: 280px;
    overflow: hidden;

    .news-item {
        position: relative;
        display: flex;
        flex-direction: column;
        height: fit-content;
    }

    .background-image {
        height: 300px;
        transition: all 2s ease;
    
    :hover {
        transform: scale(1.2);
        }
    }
`
const SVGBox = styled.div `
    position: relative;
    top: -62px;
`

const ContentBox = styled.div `    
    position: absolute;
    top: 0;
    margin: 0 1em;

    color: #fff;


    .button {
        margin: 1em 0 0 auto;
    }
`

const NewsItem = (props) => {

    const { image, type, title, color } = props.items;

    const [bHeight, getBHeight] = useState(0);
    const [bWidth, getBWidth] = useState(0);
    const [vPos, setVPos] = useState(1);
       
    let initialColor;
    switch(color) {
        case "orange": initialColor = {startColor: '#ffbc00', endColor: '#f78d1e'}; break;
        case "blue": initialColor = {startColor: '#27ddfd', endColor: '#6e8bfc'}; break;
        case "green": initialColor = {startColor: '#22d59c', endColor: '#179d84'}; break;
        case "purple": initialColor = {startColor: '#ce0fff', endColor: '#63398e'}; break;
        case "turqoise": initialColor = {startColor: '#01becf', endColor: '#6301ff'}; break;
        case "red": initialColor = {startColor: '#ff3917', endColor: '#e61b72'}; break;
        default:

        throw new Error(`Invalid color: ${color}`);
  
    }

    
    //Ref's for the two SVG box dimensions & colours
    const boxHeight = useRef();
    const boxWidth = useRef();

// Get the height of all the content set getHeight
    useLayoutEffect(() => {
        getBHeight(boxHeight.current.clientHeight*1.1);
        getBWidth(boxWidth.current.clientWidth);
        setVPos(bWidth/10); //Balancing value to move the 'V' into shot. Could be made into slider for CMS.
    }, [boxHeight, boxWidth, bWidth] );




//SVG is being stretched to fit the Content-Box - based on it's height and width. SVG paths' edges are linked to this test.
    return (
        <Box className="grid-item" ref={boxWidth} style={{height: bHeight+240}}>
                <GatsbyImage className="background-image" image={image.childImageSharp.gatsbyImageData} alt={props.slug} />
                <SVGBox>
                <svg width={bWidth} height={bHeight} fill="none" viewBox={"0 0 " + bWidth + " " + bHeight} xmlns="http://www.w3.org/2000/svg" >
                    <defs>
                        <linearGradient id={"grad-" + color} x1="0%" y1="0%" x2="112%" y2="0%">
                            <stop offset="0%" style={{stopColor: initialColor.startColor, stopOpacity: 1}} />
                            <stop offset="100%" style={{stopColor: initialColor.endColor, stopOpacity: 1}} />
                        </linearGradient>
                    </defs>
                    <path d={"M" + bWidth + " " + bHeight + "V0H" + (260-vPos) + "L" + (231-vPos) + " 62.5L" + (202-vPos) + " 0H0V" + bHeight + "H300Z"} fill={"url(#grad-" + color + ")"} />
                </svg>
                <ContentBox ref={boxHeight}>
                    <h4>{type}</h4>
                <h3>{title}</h3>
                <div dangerouslySetInnerHTML={{ __html: props.excerpt }}/>
                <PageButton styleName="button white" linkRef={props.slug}>Find Out More</PageButton>
                </ContentBox>
                </SVGBox>
        </Box>
    )
}

NewsItem.propTypes = {
    bHeight: PropTypes.number,
    bWidth: PropTypes.number,
    vPos: PropTypes.number,
    grad: PropTypes.string,
    }

export default NewsItem